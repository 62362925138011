import type {Page} from '@PosterWhiteboard/page/page.class';
import {v4 as uuidv4} from 'uuid';
import {getUnlockControl, ITEM_CONTROL_DIMENSIONS, ItemBorderColor} from '@PosterWhiteboard/poster/poster-item-controls';
import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {CornerPoints, CornerPointsArray} from '@Utils/math.util';
import {degreesToRadians, degToRad, doPolygonsIntersect} from '@Utils/math.util';
import {ItemBorder} from '@PosterWhiteboard/classes/item-border.class';
import type {ImageItem} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import type {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import type {TextSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/text-slide-item.class';
import type {ImageSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import type {VideoSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {FancyTextItem} from '@PosterWhiteboard/items/fancy-text-item/fancy-text-item.class';
import type {TabsItem} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';
import type {VideoItem} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {RGB} from '@Utils/color.util';
import type {VectorItem} from '@PosterWhiteboard/items/vector-item/vector-item.class';
import type {StickerItem} from '@PosterWhiteboard/items/sticker-item.class';
import type {QRCodeItem} from '@PosterWhiteboard/items/qr-code-item.class';
import {AuraType, ItemAura} from '@PosterWhiteboard/classes/item-aura.class';
import {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {cloneAsAlignedImage} from '@Utils/fabric.util';
import {updateSidebarState} from '@Components/poster-editor/poster-editor-reducer';
import {ItemLoading} from '@PosterWhiteboard/items/item/item-loading.class';
import {ActiveSelection, util} from '@postermywall/fabricjs-2';
import type {FabricObject, Shadow} from '@postermywall/fabricjs-2';
import type {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import {POSTER_VERSION} from '@PosterWhiteboard/poster/poster.types';
import type {RectangleItem} from '@PosterWhiteboard/items/rectangle-item/rectangle-item';
import type {LineItem} from '@PosterWhiteboard/items/line-item/line-item';
import type {BaseItemObject, ItemKeysFromFabricObject, ItemObject, ItemsWithFonts} from './item.types';
import {SHADOW_REFERENCE_DIMENSION, ITEM_TYPE} from './item.types';
import type {DeepPartial} from '@/global';

export type ItemFabricObject = FabricObject;

export abstract class Item extends CommonMethods {
  public abstract gitype: ITEM_TYPE;

  public uid: string;
  public page: Page;
  public pmvcName = '';
  public x = 0;
  public y = 0;
  public alpha = 1;
  public idOriginalOwner?: number;
  public width = 0;
  public height = 0;
  public rotation = 0;
  public visible = true;
  public erasable = false;
  public scaleX = 1;
  public scaleY = 1;
  public flipX = false;
  public flipY = false;
  public lockMovement = false;
  public version = 1;
  public clickableLink = '';
  public fabricObject!: ItemFabricObject;
  public border: ItemBorder;
  public loading: ItemLoading;
  public aura: ItemAura;
  public setActiveOnLoad = true; // TODO: See if we still need this
  public isNew = true; // TODO: Impelement this
  private reinitObject = false;
  protected isInitialzed = false;

  public constructor(page: Page) {
    super();
    this.border = new ItemBorder(this);
    this.aura = new ItemAura();
    this.loading = new ItemLoading(this);
    this.page = page;
    this.uid = uuidv4();
  }

  /**
   * Whether this item can safely be converted to svg and inserted in PDF or
   * needs to be rasterized. Defaults to false and will rasterize all items
   * unless overridden by a child class.
   * @returns {Promise<boolean>}
   */
  public async isPDFSafe(): Promise<boolean> {
    return false;
  }

  public async getFabricObjectsForPDF(): Promise<Array<ItemFabricObject>> {
    const objects = [];
    const isCompatible = await this.isPDFSafe();

    if (!isCompatible) {
      const options = this.isText() || (this.isSlideshow() && this.slides.isFirstSlideNonEmptyText()) ? {expandBoundingBoxByFont: true} : {};
      objects.push(
        await cloneAsAlignedImage(
          this.fabricObject,
          {
            ...options,
            multiplier: this.page.poster.scaling.scale,
          },
          ['__PMWID']
        )
      );
    } else {
      objects.push(this.fabricObject);
    }

    return objects;
  }

  public hasClickableLink(): boolean {
    return !!this.clickableLink;
  }

  public isMotionItem(): this is VideoItem | StickerItem {
    return this.gitype === ITEM_TYPE.VIDEO || this.gitype === ITEM_TYPE.STICKER;
  }

  public isVector(): this is VectorItem {
    return this.gitype === ITEM_TYPE.VECTOR;
  }

  public isText(): this is TextItem {
    return this.gitype === ITEM_TYPE.TEXT;
  }

  public isTranscript(): this is TranscriptItem {
    return this.gitype === ITEM_TYPE.TRANSCRIPT;
  }

  public isQRItem(): this is QRCodeItem {
    return this.gitype === ITEM_TYPE.QR_CODE;
  }

  public isVideo(): this is VideoItem {
    return this.gitype === ITEM_TYPE.VIDEO;
  }

  public isImage(): this is ImageItem {
    return this.gitype === ITEM_TYPE.IMAGE;
  }

  public isSticker(): this is StickerItem {
    return this.gitype === ITEM_TYPE.STICKER;
  }

  public isFancyText(): this is FancyTextItem {
    return this.gitype === ITEM_TYPE.FANCY_TEXT;
  }

  public isSlideshow(): this is SlideshowItem {
    return this.gitype === ITEM_TYPE.SLIDESHOW;
  }

  public isTextSlide(): this is TextSlideItem {
    return this.gitype === ITEM_TYPE.TEXTSLIDE;
  }

  public isImageSlide(): this is ImageSlideItem {
    return this.gitype === ITEM_TYPE.IMAGESLIDE;
  }

  public isVideoSlide(): this is VideoSlideItem {
    return this.gitype === ITEM_TYPE.VIDEOSLIDE;
  }

  public isRectangle(): this is RectangleItem {
    return this.gitype === ITEM_TYPE.RECTANGLE;
  }

  public isLine(): this is LineItem {
    return this.gitype === ITEM_TYPE.LINE;
  }

  public isSlideshowSlide(): this is SlideshowItem {
    return this.isTextSlide() || this.isMediaSlide();
  }

  public isMediaSlide(): this is ImageSlideItem | VideoSlideItem {
    return this.isImageSlide() || this.isVideoSlide();
  }

  public isMenu(): this is MenuItem {
    return this.gitype === ITEM_TYPE.MENU;
  }

  public isTable(): this is TableItem {
    return this.gitype === ITEM_TYPE.TABLE;
  }

  public isTab(): this is TabsItem {
    return this.gitype === ITEM_TYPE.TAB;
  }

  public isSchedule(): boolean {
    return this.isTable() && this.layoutStyle !== LayoutTypes.CUSTOM_TABLE_LAYOUT;
  }

  public hasEditableText(): this is ItemsWithFonts {
    return (
      this.gitype === ITEM_TYPE.TEXT ||
      this.gitype === ITEM_TYPE.MENU ||
      this.gitype === ITEM_TYPE.TRANSCRIPT ||
      this.gitype === ITEM_TYPE.TAB ||
      this.gitype === ITEM_TYPE.TABLE ||
      this.gitype === ITEM_TYPE.SLIDESHOW
    );
  }

  protected isVisible(): boolean {
    return this.visible;
  }

  public updateBoundItemsZIndex(): void {
    this.loading.updateZIndex();
  }

  public async invalidate(reinitItem = false, shouldMaintainScaledDimensionsOnReinit = false): Promise<void> {
    if (reinitItem) {
      await this.reInitFabricObject(shouldMaintainScaledDimensionsOnReinit);
    }

    if (this.fabricObject.group === undefined) {
      this.setControlsVisibility();
      await this.updateFabricObject();
    }
    this.loading.invalidate();
    if (!this.page.poster.mode.isGeneration()) {
      this.page.fabricCanvas.requestRenderAll();
    }
  }

  protected reinitingItem = false;

  protected applyObjectUpdates(itemObj: DeepPartial<ItemObject>): DeepPartial<ItemObject> {
    if (this.shouldChangeDimensionsInsteadOfScale()) {
      const {scaleX, scaleY, width, height, ...obj} = itemObj;

      const updatedWidth = scaleX ? (width !== undefined ? width : this.width) * scaleX : undefined;
      const updatedHeight = scaleY ? (height !== undefined ? height : this.height) * scaleY : undefined;

      return {
        ...obj,
        ...(updatedWidth !== undefined && {width: updatedWidth}),
        ...(updatedHeight !== undefined && {height: updatedHeight}),
      };
    }

    return {
      ...itemObj,
    };
  }

  public updateSize(scale: number): void {
    if (this.shouldChangeDimensionsInsteadOfScale()) {
      this.width *= scale;
      this.height *= scale;
    } else {
      this.scaleX *= scale;
      this.scaleY *= scale;
    }
  }

  public async updateFromObject(
    obj: DeepPartial<ItemObject>,
    {updateRedux = true, undoable = true, doInvalidate = true, forceInit = false, checkForDurationUpdate = false, replayPosterOnUpdateDone = false}: UpdateFromObjectOpts = {}
  ): Promise<void> {
    if (this.reinitingItem) {
      return;
    }

    const updatedObj = this.applyObjectUpdates(obj);
    try {
      const additionalOldValuesForReinit = this.getAdditionalOldValuesForReinit();
      const oldItemObject = this.toObject();
      this.copyVals(updatedObj);
      const reinit = forceInit || (this.isInitialzed && this.itemObjectHasDestructiveChanges(oldItemObject, additionalOldValuesForReinit));
      if (reinit) {
        this.reinitingItem = true;
      }
      await this.init();
      if (doInvalidate || reinit) {
        await this.invalidate(reinit, this.shouldMaintainScaledDimensionsOnReinit(oldItemObject, additionalOldValuesForReinit));
      }
    } finally {
      if (this.reinitingItem) {
        this.reinitingItem = false;
      }
    }
    await this.onItemUpdatedFromObject();

    if (checkForDurationUpdate) {
      this.checkItemForPageDurationUpdate();
    }

    if (undoable) {
      this.page.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.page.poster.redux.updateReduxData();
    }
    if (replayPosterOnUpdateDone) {
      await this.page.poster.replayPoster();
    }
  }

  protected async onItemUpdatedFromObject(): Promise<void> {}

  protected checkItemForPageDurationUpdate(): void {
    if (!this.isChildItem() && this.isStreamingMediaItem()) {
      this.page.calculateAndUpdatePageDuration();
    }
  }

  public shouldChangeDimensionsInsteadOfScale(): boolean {
    return false;
  }

  public copyVals(obj: DeepPartial<ItemObject>): void {
    const {border, aura, ...plainObj} = obj;
    super.copyVals(plainObj);
    this.border.copyVals(border);
    this.aura.copyVals(aura);
  }

  protected getAdditionalOldValuesForReinit(): Record<string, any> {
    return {};
  }

  public async formatItemObjectWithDefaultValues(obj: DeepPartial<ItemObject>): Promise<DeepPartial<ItemObject>> {
    return {...obj};
  }

  protected itemObjectHasDestructiveChanges(oldItemObject: ItemObject | BaseItemObject, oldAdditionalValues: Record<string, any> = {}): boolean {
    return false;
  }

  protected shouldMaintainScaledDimensionsOnReinit(oldItemObject: ItemObject | BaseItemObject, oldAdditionalValues: Record<string, any> = {}): boolean {
    return false;
  }

  public toObject(): BaseItemObject {
    return {
      uid: this.uid,
      gitype: this.gitype,
      idOriginalOwner: this.idOriginalOwner,
      x: this.x,
      y: this.y,
      alpha: this.alpha,
      width: this.width,
      height: this.height,
      rotation: this.rotation,
      visible: this.visible,
      erasable: this.erasable,
      border: this.border.toObject(),
      aura: this.aura.toObject(),
      clickableLink: this.clickableLink,
      setActiveOnLoad: this.setActiveOnLoad,
      isNew: this.isNew,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      flipX: this.flipX,
      flipY: this.flipY,
      lockMovement: this.lockMovement,
      version: this.version,
    };
  }

  public toDataURL(ignoreAlpha = true): string {
    if (ignoreAlpha) {
      this.fabricObject.set('opacity', 1);
    }
    const data = this.fabricObject.toDataURL({
      format: 'png',
      quality: 1,
    });
    if (ignoreAlpha) {
      this.fabricObject.set('opacity', this.alpha);
    }
    return data;
  }

  public isStreamingMediaItem(): boolean {
    return false;
  }

  public isChildItem(): boolean {
    return false;
  }

  /**
   * Returns the duration of item to play
   * This differs from getDuration as this returns not the actual duration but the duration for which the item should be played on poster
   * i.e. for stickers this returns 5 seconds because we wanted stickers to loop for 5 secs
   * @return {number}
   */
  public getPlayDuration(): number {
    return this.getDuration();
  }

  public getDuration(): number {
    return 0;
  }

  public async pause(): Promise<void> {}

  public async stop(): Promise<void> {}

  public async initFabricObject(shouldMaintainScaledDimensions = false): Promise<void> {
    const fabricObject = await this.getFabricObjectForItem(shouldMaintainScaledDimensions);
    this.setFabricObject(fabricObject);
  }

  protected setFabricObject(fabricObject: ItemFabricObject): void {
    this.fabricObject = fabricObject;
    this.fabricObject.__PMWID = this.uid;
    this.initEvents();
    this.initCustomControls();
    this.updateItemDimensions();
    this.setControlsVisibility();
  }

  protected updateItemDimensions(): void {
    if (this.fabricObject) {
      this.width = this.fabricObject.width ?? 0;
      this.height = this.fabricObject.height ?? 0;
    }
  }

  protected initCustomControls(): void {
    const control = getUnlockControl();
    this.fabricObject.controls[control.key] = control.control;
  }

  protected initEvents(): void {
    this.fabricObject.on('modified', this.onFabricObjectModified.bind(this));
    this.fabricObject.on('mousedblclick', this.onDoubleClickItem.bind(this));
  }

  private isSelectedItemTextSlideOrText(): boolean {
    if (!this.page.isSingleItemSelected()) {
      return false;
    }

    const selectedItem = this.page.getSelectedItems()[0];
    return !!(selectedItem.isText() || (selectedItem.isSlideshow() && selectedItem.getSelectedSlide().isTextSlide()));
  }

  private openEditingSidebar(): void {
    if (this.isSelectedItemTextSlideOrText()) {
      return;
    }
    if (window.PMW.redux.store.getState().posterEditor.isSidebarSmall) {
      window.PMW.redux.store.dispatch(updateSidebarState(true));
    }
  }

  private onDoubleClickItem(): void {
    if (this.page.isSingleItemSelected()) {
      this.onItemDoubleClicked();
    }

    this.openEditingSidebar();
  }

  protected onDoubleTapItem(): void {
    this.openEditingSidebar();

    if (!window.PMW.redux.store.getState().posterEditor.isMobileVariant) {
      return;
    }
    if (this.page.isSingleItemSelected() && this.page.activeSelection.getActiveObjects()[0] === this.fabricObject) {
      this.onItemDoubleTapped();
    }
  }

  protected onItemDoubleTapped(): void {}

  protected onItemDoubleClicked(): void {}

  public isPremium(): boolean {
    return false;
  }

  public getBorderColor(): ItemBorderColor {
    return this.isStreamingMediaItem() ? ItemBorderColor.DYNAMIC_ITEM : ItemBorderColor.STATIC_ITEM;
  }

  protected onFabricObjectModified(): void {
    this.updateFromObject(this.getValuesOnFabricObjectModified()).catch((e) => {
      console.error(`Failed to update item on fabric object modified, Details:${JSON.stringify(e)}`);
    });
  }

  protected getValuesOnFabricObjectModified(): ItemKeysFromFabricObject {
    return {
      x: this.fabricObject.left,
      y: this.fabricObject.top,
      width: this.fabricObject.width,
      height: this.fabricObject.height,
      rotation: this.fabricObject.angle,
      scaleX: this.fabricObject.scaleX,
      scaleY: this.fabricObject.scaleY,
      flipX: this.fabricObject.flipX,
      flipY: this.fabricObject.flipY,
    };
  }

  protected async updateFabricObject(): Promise<void> {
    this.fabricObject.set({
      ...this.getCommonOptions(),
    });

    this.applyBorder();
    // TODO: Call setCoords only when top or left of the item changes. (http://fabricjs.com/fabric-gotchas)
    this.fabricObject.setCoords();
    this.applyShadowToFabricObject();
  }

  protected applyBorder(): void {
    this.fabricObject.set({
      ...this.border.getBorder(),
    });
  }

  protected applyShadowToFabricObject(): void {
    this.fabricObject.set('shadow', this.getShadow());
  }

  protected getCommonOptions(): Record<string, any> {
    return {
      ...this.getPositionOptions(),
      ...this.getCommonOptionsForWebpage(),
      angle: this.rotation,
      opacity: this.alpha,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      visible: this.visible,
      lockMovementX: this.lockMovement,
      lockMovementY: this.lockMovement,
      lockRotation: this.lockMovement,
      lockScalingX: this.lockMovement,
      lockScalingY: this.lockMovement,
      hasControls: true,
      borderColor: this.getBorderColor(),
      lockSkewingX: true,
      lockSkewingY: true,
      flipX: this.flipX,
      flipY: this.flipY,
      erasable: this.erasable,
      padding: this.getSelectorPadding(),
    };
  }

  private getCommonOptionsForWebpage(): Record<string, any> {
    if (this.page.poster.mode.isWebpage() || this.page.poster.mode.isGeneration()) {
      return {
        selectable: false,
        hoverCursor: this.isSelectableInWebpage() ? 'pointer' : 'default',
        perPixelTargetFind: true,
      };
    }
    return {};
  }

  protected getInitOptionsForView(): Record<string, any> {
    return {};
  }

  protected getPositionOptions(): Record<string, any> {
    return {
      top: this.y,
      left: this.x,
    };
  }

  protected async reInitFabricObject(shouldMaintainScaledDimensionsOnReinit = false): Promise<void> {
    const wasItemActive = this.isActive();
    const zIndex = this.page.items.getItemOrder(this.uid);
    const oldFabricObject = this.fabricObject;
    this.beforeInitFabricObject();
    await this.initFabricObject(shouldMaintainScaledDimensionsOnReinit);
    if (this.page.fabricCanvas.contains(oldFabricObject)) {
      if (zIndex === undefined) {
        this.page.fabricCanvas.add(this.fabricObject);
      } else {
        this.page.fabricCanvas.insertAt(zIndex, this.fabricObject);
      }

      this.page.fabricCanvas.remove(oldFabricObject);
      if (wasItemActive) {
        this.reselectItem(oldFabricObject);
      }
    }
  }

  private reselectItem(oldFabricObject: ItemFabricObject): void {
    const activeObjects = this.page.activeSelection.getActiveObjects();
    const newActiveObjects = [];

    if (!activeObjects) {
      return;
    }

    for (const activeObject of activeObjects) {
      if (activeObject === oldFabricObject) {
        newActiveObjects.push(activeObject);
      } else {
        newActiveObjects.push(activeObject);
      }
    }

    this.page.activeSelection.discardActiveObject();

    if (activeObjects.length > 1) {
      const sel = new ActiveSelection(newActiveObjects, {
        canvas: this.page.fabricCanvas,
      });
      this.page.activeSelection.setActiveObject(sel);
    } else {
      this.page.activeSelection.setActiveObject(this.fabricObject);
    }
  }

  protected setControlsVisibility(): void {
    const isItemLocked = this.isLocked();
    const shouldHideControlsOnSmallItem = this.shouldHideControlOnSmallItem();

    this.fabricObject.setControlsVisibility({
      tl: !isItemLocked && !shouldHideControlsOnSmallItem,
      tr: !isItemLocked,
      bl: !isItemLocked,
      br: !isItemLocked && !shouldHideControlsOnSmallItem,
      mtr: !isItemLocked,
      unlockBtn: isItemLocked,
    });
  }

  protected shouldHideControlOnSmallItem(): boolean {
    const minDimension = Math.min(this.getScaledHeight(), this.getScaledWidth());
    return minDimension * this.page.poster.scaling.scale < 20;
  }

  public getSelectorPadding(): number {
    return ITEM_CONTROL_DIMENSIONS.PMW_CONTROL_PADDING;
  }

  protected hasParentGroupGraphicItem(): boolean {
    return !!this.fabricObject.group && !!getFabricObjectUID(this.fabricObject.group);
  }

  protected hasOrderedList(): boolean {
    return false;
  }

  public getParentFabricObject(): ItemFabricObject {
    return this.fabricObject;
  }

  public doesfabricObjBelongtoItem(fabricObj: FabricObject): boolean {
    return this.fabricObject === fabricObj;
  }

  public onMoving(): void {
    this.loading.updateLoadingPositionAndRender();
  }

  public onRotating(): void {
    this.loading.updateLoadingPositionAndRender();
  }

  public onScaling(): void {
    this.loading.updateTextOnItemResizeAndRender();
  }

  protected abstract getFabricObjectForItem(shouldMaintainScaledDimensions?: boolean): Promise<FabricObject>;

  public getScaledHeight(): number {
    return this.scaleY * this.height;
  }

  public getScaledWidth(): number {
    return this.scaleX * this.width;
  }

  public getZIndex(): number {
    return this.page.fabricCanvas.getObjects().indexOf(this.fabricObject);
  }

  public isOnBottom = (): boolean => {
    return this.uid === this.page.items.getBottomItem()?.uid;
  };

  public canItemBeMovedForward = (): boolean => {
    return !(this.isOnTop() || this.page.items.getNumberOfItemsOnPage() === 1);
  };

  public canItemBeMovedBackwards = (): boolean => {
    return !(this.isOnBottom() || this.page.items.getNumberOfItemsOnPage() === 1);
  };

  public isOnTop = (): boolean => {
    return this.uid === this.page.items.getTopItem()?.uid;
  };

  public isLocked(): boolean {
    return this.lockMovement;
  }

  public isActive(): boolean {
    const parentItemPmwId = this.getParentFabricObject().__PMWID;
    const activeObjects = this.page.activeSelection.getActiveObjects();
    if (activeObjects) {
      for (const activeObject of activeObjects) {
        if (activeObject.__PMWID === parentItemPmwId) {
          return true;
        }
      }
    }
    return false;
  }

  public async init(): Promise<void> {
    if (!this.isInitialzed) {
      this.fixChanges();
      this.beforeInitFabricObject();
      await this.initFabricObject(true);
      this.onInitItem();
      this.isInitialzed = true;
    }
  }

  protected fixChanges(): void {
    this.fixAura();
  }

  protected fixAura(): void {
    if (this.page.poster.version < POSTER_VERSION.SHADOW_IMPROVEMENT) {
      if (this.aura.isShadow()) {
        let newShadowDistance;
        const shadowScale = this.getScaleForShadow();

        if (this.aura.isCustomShadow()) {
          newShadowDistance = Math.round(
            ((this.getOldShadowDistance() + 2) * Math.cos(degreesToRadians(this.aura.dropShadowAngle))) / (shadowScale * Math.cos(degreesToRadians(this.aura.dropShadowAngle)))
          );
        } else {
          this.aura.dropShadowColor = [0, 0, 0];
          this.aura.dropShadowAlpha = this.aura.type === AuraType.LIGHT_SHADOW ? 0.25 : 0.5;
          this.aura.dropShadowAngle = 45;
          newShadowDistance = Math.round(this.getOldShadowDistance() / (shadowScale * Math.cos(degreesToRadians(this.aura.dropShadowAngle))));
        }
        this.aura.dropShadowDistance = newShadowDistance;
        this.aura.dropShadowBlur = Math.round(this.getOldShadowBlur() / shadowScale);
      }
    }
  }

  protected getScaleForShadow(): number {
    const maxDimension = Math.max(this.height, this.width);
    return maxDimension / SHADOW_REFERENCE_DIMENSION;
  }

  protected getShadow(): Shadow | null {
    return this.aura.getItemAura(this.getScaleForShadow());
  }

  protected getOldShadowBlur(): number {
    return 2;
  }

  protected getOldShadowDistance(): number {
    return 6;
  }

  protected onInitItem(): void {}

  public beforeInitFabricObject(): void {}

  public async onItemAddedToPage(): Promise<void> {
    // override if needed
  }

  public onRemove(): void {
    this.loading.removeLoading();
  }

  public async stretchToCanvasHorizontally(leaveSpace = false): Promise<void> {
    const cornerPoints = this.getCornerPointsArray();
    const tlCorner = cornerPoints[0];
    let offset = 0;

    if (leaveSpace) {
      offset = this.page.poster.width * 0.2;
    }
    const newScaleX = ((this.page.poster.width - offset) * this.scaleX) / this.getBoundingBoxWidth();
    const newScaleY = (newScaleX / this.scaleX) * this.scaleY;

    // The left and top property of vo doesn't represent the tl corner appearing on the canvas if the graphic item is rotated. So we first find out the corner which has the least value of x and make it 0 so that
    // the whole graphic item remains on the canvas. After finding out the left most corner we set the left property of vo such that this corner gets x-axis value as 0
    let leftMostCornerPointIndex = 0;
    let newTlCornerXCoordinate = -this.getManualSelectorPadding();

    for (let i = 0; i < cornerPoints.length; i++) {
      if (cornerPoints[i].x < cornerPoints[leftMostCornerPointIndex].x) {
        leftMostCornerPointIndex = i;
      }
    }

    if (leftMostCornerPointIndex !== 0) {
      const selectorPaddingOffset = this.getManualSelectorPadding() * 2;
      newTlCornerXCoordinate = ((tlCorner.x - cornerPoints[leftMostCornerPointIndex].x - selectorPaddingOffset) * newScaleX) / this.scaleX;
    }

    await this.updateFromObject({
      x: newTlCornerXCoordinate,
      scaleX: newScaleX,
      scaleY: newScaleY,
    });
  }

  public async stretchToCanvasVertically(leaveSpace = false): Promise<void> {
    const cornerPoints = this.getCornerPointsArray();
    const tlCorner = cornerPoints[0];
    let offset = 0;

    if (leaveSpace) {
      offset = this.page.poster.height * 0.2;
    }
    const newScaleY = ((this.page.poster.height - offset) * this.scaleY) / this.getBoundingBoxHeight();
    const newScaleX = (newScaleY / this.scaleY) * this.scaleX;

    // The left and top property of vo doesn't represent the tl corner appearing on the canvas if the graphic item is rotated. So we first find out the corner which has the least value of y and make it 0 so that
    // the whole graphic item remains on the canvas. After finding out the top most corner we set the top property of vo such that this corner gets y-axis value as 0
    let topMostCornerPoint = 0;
    let newTlCornerYCoordinate = -this.getManualSelectorPadding();

    for (let j = 0; j < cornerPoints.length; j++) {
      if (cornerPoints[j].y < cornerPoints[topMostCornerPoint].y) {
        topMostCornerPoint = j;
      }
    }

    if (topMostCornerPoint !== 0) {
      const selectorPaddingOffset = this.getManualSelectorPadding() * 2;
      newTlCornerYCoordinate = ((tlCorner.y - cornerPoints[topMostCornerPoint].y - selectorPaddingOffset) * newScaleY) / this.scaleY;
    }

    await this.updateFromObject({
      y: newTlCornerYCoordinate,
      scaleX: newScaleX,
      scaleY: newScaleY,
    });
  }

  public getBoundingBoxWidth(): number {
    const cornerPoints = this.getCornerPointsArray();
    let leftMostCornerPointIndex = 0;
    let rightMostCornerPointIndex = 0;

    for (let i = 0; i < cornerPoints.length; i++) {
      if (cornerPoints[i].x < cornerPoints[leftMostCornerPointIndex].x) {
        leftMostCornerPointIndex = i;
      }
      if (cornerPoints[i].x > cornerPoints[rightMostCornerPointIndex].x) {
        rightMostCornerPointIndex = i;
      }
    }
    return cornerPoints[rightMostCornerPointIndex].x - cornerPoints[leftMostCornerPointIndex].x;
  }

  public getBoundingBoxHeight(): number {
    const cornerPoints = this.getCornerPointsArray();
    let topMostCornerPointIndex = 0;
    let bottomMostCornerPointIndex = 0;

    for (let i = 0; i < cornerPoints.length; i++) {
      if (cornerPoints[i].y < cornerPoints[topMostCornerPointIndex].y) {
        topMostCornerPointIndex = i;
      }
      if (cornerPoints[i].y > cornerPoints[bottomMostCornerPointIndex].y) {
        bottomMostCornerPointIndex = i;
      }
    }
    return cornerPoints[bottomMostCornerPointIndex].y - cornerPoints[topMostCornerPointIndex].y;
  }

  /**
   * Returns the manually added selector padding.
   */
  public getManualSelectorPadding(): number {
    return 0;
  }

  public getCornerPointsArray(): CornerPointsArray {
    const cornerPoints = this.getCornerPoints();
    return [cornerPoints.tl, cornerPoints.tr, cornerPoints.br, cornerPoints.bl];
  }

  public getCornerPoints(): CornerPoints {
    const angle = this.rotation;
    let width = this.getScaledWidth();
    const height = this.getScaledHeight();
    const theta = degToRad(angle);

    if (width < 0) {
      width = Math.abs(width);
    }

    const sinTh = Math.sin(theta);
    const cosTh = Math.cos(theta);

    const tl = {
      x: this.x,
      y: this.y,
    };

    const tr = {
      x: this.x + width * cosTh,
      y: this.y + width * sinTh,
    };

    const bl = {
      x: this.x - height * sinTh,
      y: this.y + height * cosTh,
    };

    const br = {
      x: tr.x - height * sinTh,
      y: tr.y + height * cosTh,
    };

    return {
      tl,
      tr,
      br,
      bl,
    };
  }

  public hasEditMode(): boolean {
    return false;
  }

  public getColors(): Array<RGB> {
    const colors: Array<RGB> = [];
    if (this.border.hasBorder()) {
      colors.push(this.border.solidBorderColor);
    }

    if (this.aura.isCustomShadow()) {
      colors.push(this.aura.dropShadowColor);
    }

    return colors;
  }

  public isItemVisibleOnPoster(): boolean {
    return this.isVisible() && this.width > 0 && this.height > 0 && doPolygonsIntersect(this.getCornerPointsArray(), this.page.poster.getCornerPointsArray());
  }

  private isSelectableInWebpage = (): boolean => {
    return this.isSlideshow() || this.hasClickableLink();
  };

  public isOutlineDisabled(): boolean {
    if ((this.isText() || this.isTextSlide() || this.isTab()) && this.textStyles.isBold) {
      return true;
    }

    return !!((this.isMenu() || this.isTable()) && (this.isBold2 || this.textStyles.isBold));
  }

  public updateClickableLink(message: string, undoable = true): void {
    void this.updateFromObject(
      {
        clickableLink: message,
      },
      {undoable}
    );
  }

  public flipItemVertically(): void {
    void this.updateFromObject({
      flipY: !this.flipY,
    });
  }

  public flipItemHorizontally(): void {
    void this.updateFromObject({
      flipX: !this.flipX,
    });
  }

  public getFabricObjectAbsoluteLeft(): number {
    if (this.fabricObject.group) {
      return util.transformPoint({x: -this.fabricObject.width / 2, y: -this.fabricObject.height / 2}, this.fabricObject.calcTransformMatrix()).x;
    }

    return this.fabricObject.left;
  }

  public getFabricObjectAbsoluteTop(): number {
    if (this.fabricObject.group) {
      return util.transformPoint({x: -this.fabricObject.width / 2, y: -this.fabricObject.height / 2}, this.fabricObject.calcTransformMatrix()).y;
    }

    return this.fabricObject.top;
  }

  public getFabricObjectAbsoluteAngle(): number {
    if (this.fabricObject.group) {
      return util.qrDecompose(this.fabricObject.calcTransformMatrix()).angle;
    }

    return this.fabricObject.angle;
  }

  public getFabricObjectAbsoluteScaledWidth(): number {
    if (this.fabricObject.group) {
      return util.qrDecompose(this.fabricObject.calcTransformMatrix()).scaleX * this.fabricObject.width;
    }

    return this.fabricObject.getScaledWidth();
  }

  public getFabricObjectAbsoluteScaledHeight(): number {
    if (this.fabricObject.group) {
      return util.qrDecompose(this.fabricObject.calcTransformMatrix()).scaleY * this.fabricObject.height;
    }

    return this.fabricObject.getScaledHeight();
  }
}

export const getFabricObjectUID = (view: FabricObject): string => {
  return view.__PMWID;
};
